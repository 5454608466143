<template>
    <div>
        <other-header></other-header>
        <div class="hire-detail">
            <detail-header last-name="返回" :title="info.MemberName"/>
            <div class="user-center flex-col flex-j-start flex-a-center">
                <img :src="info.HeadImage" alt="" class="avatar"/>
                <div class="info">
                    <div class="flex-row flex-j-between flex-a-center">
                    <span class="flex-1">昵称：{{info.MemberName}}
                        <a v-if="info.Sex==1" class="iconfont icon-nanxingmale209" style="color: #39a9ed"></a>
                        <a v-if="info.Sex==2" class="iconfont icon-nv" style="color: #f1676d"></a>
                    </span>
                        <span class="flex-1">手机号：{{info.Phone || '--'}}</span>
                        <span class="flex-1">邮箱：{{info.Email || '--'}}</span>
                    </div>
                    <br>
                    <div class="flex-row flex-j-between flex-a-center">
                        <span class="flex-1">学历：{{info.Education || '--'}}</span>
                        <span class="flex-1">年龄：{{info.Age || '--'}}</span>
                        <span class="flex-1">婚否：{{info.Marriage | Marriage}}</span>
                    </div>
                    <br>
                    <div class="flex-row flex-j-between flex-a-center">
                        <span class="flex-1">期望岗位：{{info.BusinessName || '--'}}</span>
                    </div>
                    <br>
                    <div class="flex-row flex-j-between flex-a-center">
                        <span class="flex-1">期望工作所在地：{{info.AreaName || '--'}}</span>
                    </div>
                </div>
            </div>
            <div class="card">
                <div style="font-size: 0.4rem;font-weight: 600">个人简介</div>
                <br>
                <div class="Details" v-if="info.Details && info.Details.length > 0" v-html="info.Details"></div>
                <div v-else style="color: #666">该用户什么也没写</div>
            </div>
        </div>
    </div>
</template>

<script>
// hire-detail
import DetailHeader from "@/components/detail-header";
import OtherHeader from "@/components/other-header";
export default {
    name: "hire-detail",
    components: {OtherHeader, DetailHeader},
    data() {
        return {
            info: {}
        }
    },
    computed: {
        id() {
            return this.$route.query.id;
        }
    },
    filters: {
        Marriage: function (value) {
            console.log(value)
            if (value == 0) {
                return '保密';
            } else if (value == 1) {
                return '未婚';
            } else if (value == 2) {
                return '已婚'
            }
        }
    },
    activated() {
        this.loadData();
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            // path /BaseJobFair/GetJobFairInfo
            const params = {
                id: this.id
            };
            const res = await $app.httpGet({
                path: `/Member/GetUserInfo`,
                params: params,
            });
            if (res.ok) {
                const item = res.data;
                item.previteName = item.MemberName.substr(0, 1) + "**";
                item.BusinessName += `/${item.PostName}`;
                if (item.Phone) {
                    item.Phone += "";
                    try {
                        item.previtePhone = item.Phone.substr(0, 3) + "****" + item.Phone.substr(7, 11)
                    } catch (e) {
                        item.previtePhone = "***********"
                    }
                }
                this.info = item;
            }
        },
    }
}
</script>

<style scoped lang="less">
.hire-detail {
    width: calc(22rem);
    margin: 0.4rem auto;
    & .user-center {
        width: calc(22rem);
        background-color: #68dbce;
        padding: 0.4rem;
        border-radius: 0.12rem;
        margin: 0.2rem 0;
        box-sizing: border-box;
        box-shadow: 0 0 0.2rem 0.1rem rgba(104, 219, 206, 0.2);
        position: relative;
        & .home {
            position: absolute;
            left: 0.4rem;
            top: 0.4rem;
            color: #2c3e50;
            cursor: pointer;
            &:active {
                opacity: 0.9;
            }
        }
        & .avatar {
            width: 3.0rem;
            height: 3.0rem;
            border-radius: 0.2rem;
            border: 0.04rem solid #fff;
        }
        & .info {
            margin-top: 0.4rem;
            width: 16rem;
            color: #fff;
            text-align: left;
            font-weight: 600;
        }
    }
    & .card {
        background-color: #Fff;
        padding: 0.4rem;
        border-radius: 0.12rem;
        text-align: left;
        & .title {
            font-size: 0.4rem;
        }
        & .time {
            font-size: .28rem;
            color: #999999;
            line-height: .60rem;
        }
    }
}
</style>
